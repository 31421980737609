@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body {
  overflow-x: hidden !important;
}
@media (max-width: 767px) {
  body {
    overflow-x: hidden; 
  }
}





    @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      
      .fade-in {
        opacity: 0;
        animation: fadeIn 1s ease-out;
        animation-fill-mode: forwards; /* Keeps the final state of the animation */
      }

      .btn-our-course{
        padding: 12px 31px !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 5px !important;
        border-radius: 20px 4px !important;
        background: #1D4777 !important;
        color:white !important;
        margin-top: 50px !important;
        margin-left: 24px !important;
        border: solid  0px !important;
     }